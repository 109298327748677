* {
  box-sizing: border-box;
}

.base {
  display: flex;
}

.filters {
  margin-top: 2em;
  width: 10%;
  position: sticky;
  align-self: flex-start;
  top: 2em;
  right: 10%;
  padding: 0%;
}

.feeds {
  margin: 2em 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 60%;
}

.user {
  margin: 2em 10%;
  width: 80%;
}

.user-card {
  width: 20%;
  margin-right: 5%;
  text-align: center;
}

.user-profile {
  width: 75%;
}
